import { Link } from '@remix-run/react';
import Tooltip from '~/components/shared/Tooltip';
import type { User } from '~/models/user.server';
import './users-avatars.css';

const UsersAvatars = ({ users, loading }: { users?: User[]; loading?: boolean }) => {
  return (
    <ul className="users-avatars">
      {loading && !users && [...Array(2)].map((_, index) => <li aria-hidden key={index} className="loading" />)}
      {users &&
        users.map((user) => (
          <li key={user.id}>
            <Tooltip
              children={
                <Link aria-label={user.login as string} to={`/user/${user.login}`}>
                  {user.avatarUrl && <img src={user.avatarUrl} alt="" />}
                </Link>
              }
              text={user.login as string}
            />
          </li>
        ))}
    </ul>
  );
};

export default UsersAvatars;
